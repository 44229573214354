import React, { Fragment, useState } from 'react';
import Head from 'next/head'
import dynamic from "next/dynamic";
import { useRouter } from 'next/router';
import { GetBannerImage, GetGridData} from '../api/home/index';
import { motion } from 'framer-motion';
import {trackPage} from "../tracking/index";
import useSWR from "swr";
import Config from '../config';
import {getStaticPath, getParamsObjectFromString} from '../utils';
import { connect } from 'react-redux';
import CITIES_SLUG from '../city_constants';
import { getSelectedCityFromCookie } from '../utils/cities';
import {HOMEPAGE_CONSTANTS} from '../src/common/constants/HomePage';
import { setCityChangePopUpAction, setShowBanner } from '../redux/actions/genericActions';
import {  isAmcInstaCart } from '../utils/cart';
import {  getPageViewData } from '../utils/tracking';
import Cookies from 'js-cookie';
import store from '../redux';
import { cityChangePopupClose } from '../tracking/segment/planPage';
import { WebsiteSchema } from '@/constants/schema'
import SchemaLoader from '@/components/hoc/SchemaLoader';
import KnowMore from '@/components/salespro/KnowMore';
import products_list from '../data/products_list';
import page_data from '../data/page_data';

declare global {
  interface Window {
    analytics: any, 
    clevertap : any
  }
}

const IRLayout = dynamic(()=> import("@/components/home/IRLayout"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const EWLayout = dynamic(()=> import("@/components/home/EWLayout"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const PromotionalBanner = dynamic(()=> import("../components/home/PromotionalBanner"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const LazyLoader = dynamic(()=> import("@/hoc/LazyLoader"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const CtaBanner = dynamic(()=> import("../components/common/CtaBanner"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const Testimonial = dynamic(()=> import("../components/common/Testimonials"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const OurPartners = dynamic(()=> import("../components/about-us/Partners"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const UIModal = dynamic(()=> import("../components/common/ui-components/surface/UIModal"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const InteractiveBanner = dynamic(()=> import("@/components/home/InteractiveBanner"),
  {loading: () => <></>,ssr:true }
);

const CityChangePopUp = dynamic(()=> import("../components/common/CityChangePopUp"),
  {loading: () => <></>,ssr:true }
);

const Fetcher = () => {
  const page_details = page_data["\\"]
  const data = {
      products: products_list || [],
      testimonials: page_details?.testimonials || [],
      meta_tag_data: page_details?.meta_tag || []
  }
  return data;
};


const BannerFetcher = async  () => {
  const website_banner_image: any = await GetBannerImage();
  return website_banner_image ? website_banner_image.data : [];
}

const GridFetcher = async  () => {
  const grid_data: any = await GetGridData();
  return grid_data ? grid_data.data : [];
}

export async function getServerSideProps() {
  const data = await Fetcher()
  return {
    props: { 
      ...data, 
      pageName: 'home-page' 
    },
  }
}

const Home = (props: any) => {
  const router = useRouter()
  const [view, setView] = useState("AmcIr")
  const BASE_URL = Config.BASE_URL;
  const OG_IMAGE = getStaticPath("/static/images/why-onsitego.png")

  React.useEffect(()=>{
      trackPage("Home Page",{...getPageViewData()})
      let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
        if(params['config'])
        {
           if(params['config']=='ir')
           {
             setView("AmcIr")
           }
           else if(params['config']=='ew')
           {
             setView("ProtectionPlans")
           }
        }
        Cookies.set('is_custom_page', false)
  },[])

  React.useEffect(()=>{
      let params:any = getParamsObjectFromString(window.location.href)['urlParams'];
      if(!params['config'])
      {
        if(getSelectedCityFromCookie())
        if(CITIES_SLUG[getSelectedCityFromCookie().toUpperCase()])
        {
          setView("AmcIr")
        }
        else
        {
          setView("ProtectionPlans")
        }
      }
  },[getSelectedCityFromCookie(), props])

  let cached_props:any = props || {};

  const {data:banner_data} = useSWR("banner", BannerFetcher,{
    revalidateOnFocus:false,
    revalidateOnMount:true
  });

  const {data:top_grid_data} = useSWR("grid_data", GridFetcher,{
    revalidateOnFocus:false,
    revalidateOnMount:true
  });

  const changeCity = props.cart.quantity && isAmcInstaCart(props.cart) && props.generic.cityChangePopup;
  
  const close = () => {
      let city_data = props.generic?.city_data;
      let cookie_city = getSelectedCityFromCookie()
      if( cookie_city!= city_data.name){
        router.push("/")
      }
      cityChangePopupClose({'source':'home page','value':'Not Changed','current_city':cookie_city,'updated_city':''})
      store.dispatch(setCityChangePopUpAction({state:false}))
  }
  return (
    <motion.div className="app-base" initial="pageInitial" animate="pageAnimate" variants={{
      pageInitial: {
        opacity: 0
      },
      pageAnimate: {
        opacity: 1
      }
    }}>
      <Fragment>
        <Head>
          <title>{cached_props?.meta_tag_data?.tag_title}</title>
          <link rel="icon" href="/favicon.ico" />
          <meta name="description" content={cached_props?.meta_tag_data?.tag_description}></meta>
          {
            props.noindex && <meta name="ROBOTS" content="NOINDEX"></meta> 
          }
          <meta property="og:title" content={cached_props?.meta_tag_data?.tag_title}/>
          <meta property="og:description" content={cached_props?.meta_tag_data?.tag_description}/>
          <meta property="og:url" content={`${BASE_URL}`}/>
          <meta property="og:image" content={OG_IMAGE}/>
          <link rel="canonical" href={`${BASE_URL}`}></link>
          <SchemaLoader schema={WebsiteSchema} />
        </Head>
        {
          <>
            {banner_data?.length !== 0 && <PromotionalBanner banners={banner_data}/>}
            <main>
              <CtaBanner products={cached_props?.products !== undefined ? cached_props?.products : []} ctaAction={(e) => {
                localStorage.setItem('journey_starts_from', 'search');// Setting localStorage parameter for GTM
                router.push(`?question_popup=${e.value}`)
              }} title="Expert Care For Your Devices" subtitle="One-Stop Solution for all your devices. Choose from a range of protection plans, repair & maintenance services." />
              {
                view == 'AmcIr' ?
                <IRLayout {...HOMEPAGE_CONSTANTS.AmcIr} TopGridProps={top_grid_data && top_grid_data.AmcIr}/> :
                <EWLayout {...HOMEPAGE_CONSTANTS.ProtectionPlans} TopGridProps={top_grid_data && top_grid_data.ProtectionPlans}/> 
              }
              {
                changeCity && 
                  <UIModal isOpen={changeCity} onClick={close} onOverlayClick={()=>{close()}}>
                    <CityChangePopUp router={router} source='home page'/>
                  </UIModal>
              }
            </main>
            { cached_props.testimonials.length != 0 && <LazyLoader><Testimonial testimonial={cached_props.testimonials} fromHome={true} router={router} bgColor="white_bg" borderBottom={"true"}/></LazyLoader> }
            <LazyLoader><OurPartners IsHeading={true} fromHome={true} /></LazyLoader>
            <LazyLoader><KnowMore /></LazyLoader>
            <LazyLoader><InteractiveBanner /></LazyLoader>
          </>
        }
      </Fragment>
    </motion.div>  )
}

const mapStateToProps = (state) => {
  return {
      generic: state.genericReducer,
      cart: state.cart
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setShowBanner: (data:any) => dispatch(setShowBanner(data)),
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Home);
