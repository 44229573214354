import React,{Fragment, useEffect} from 'react';
import Button from '../../../../components/common/ui-components/input/Button';
import router from "next/router";
import styles from './knowmore.module.scss';
import { cleverTapTrigger } from '../../../../tracking/segment';


const KnowMore = (props) => {

    const redirectToSalespro = () => {
        cleverTapTrigger("Partner With Us Clicked", {})
        router.push('/salespro-app-for-retailers')
    }
    
    return (
    <Fragment>
        <div className={`container ${styles.parent_container}`}>
            <div className={styles.content}>
                <div className={styles.heading}>
                    Want to partner with us ?
                </div>
                <div className={styles.cta_buttons}>
                    <Button onClick={redirectToSalespro}>Know More</Button>
                </div>
            </div>
        </div>
    </Fragment>
    );
}


export default KnowMore;